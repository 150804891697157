import * as React from 'react';
import { Edit, SimpleForm, TextInput, Toolbar, SaveButton, DeleteButton, ShowButton } from 'react-admin';

const UserCreateToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton label="Enregistrer" />
      <DeleteButton style={{ marginLeft: '50px' }} label="supprimer" />
      {/* <ShowButton style={{ marginLeft: '50px' }} label="voir" /> */}
    </Toolbar>
  );

  
const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm toolbar={<UserCreateToolbar />}>
            <TextInput disabled source="id" />
            <TextInput source="lastname" label="Nom" />
            <TextInput source="firstname" label="Prénom" />
            <TextInput source="email" label="E-mail" />
            <TextInput source="company" label="Société" />
        </SimpleForm>
    </Edit>
);


export default UserEdit;
