import * as React from 'react';
import { Create, SimpleForm, TextInput, SaveButton, Toolbar, SelectInput } from 'react-admin';

const UserCreateToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton label="Enregistrer" />
    </Toolbar>
  );

const CustomerCreate = (props) => {

    return (
        <Create {...props}>
            <SimpleForm toolbar={<UserCreateToolbar />}>
                <TextInput translateChoice={false} source="name" label="Nom" />
                <TextInput translateChoice={false} source="customerFunction" label="Fonction" />
                <SelectInput source="customerStatus" label="Statut" choices={[
                { id: 'Prospect', name: 'Prospect' },
                { id: 'Avancé', name: 'Avancé' },
                { id: 'Client', name: 'Client' },
                ]} />
                <TextInput translateChoice={false} source="company" label="Nom de la société" />
                <TextInput translateChoice={false} source="address" label="Adresse" />
                <TextInput translateChoice={false} source="zipCode" label="Adresse Postale" />
                <TextInput translateChoice={false} source="city" label="Ville" />
                <TextInput translateChoice={false} source="country" label="Pays" />
                <TextInput translateChoice={false} source="phone" label="Téléphone" />
                <TextInput translateChoice={false} source="email" label="E-mail" />
                <TextInput translateChoice={false} source="entity" label="Entité" />
                <TextInput translateChoice={false} source="carrierAccountCode" label="Code de compte du transporteur" />
                <TextInput translateChoice={false} source="comment" label="Commentaire" />
            </SimpleForm>
        </Create>
    );
};

export default CustomerCreate;