import * as React from "react";
import { TopToolbar, CreateButton, sanitizeListRestProps, ExportButton } from 'react-admin';
import ImportButton from './ImportButton';



const ListActions = (props) => {
    const {
        className,
        basepath,
        total,
        resource,
        currentSort,
        filterValues,
        permanentFilter,
        hasCreate,
        ...rest
    } = props;

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {hasCreate && <CreateButton label="Ajouter un client" basepath={basepath} />}
            <ImportButton />
        </TopToolbar>
    );
};

export default ListActions;
