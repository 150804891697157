import * as React from 'react';
import { Create, SimpleForm, TextInput, Toolbar, SaveButton } from 'react-admin';
import bcrypt from 'bcryptjs';

const UserCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton label="Enregistrer" />
  </Toolbar>
);

const UserCreate = (props) => {
  const transform = (data) => ({
    ...data,
    password: bcrypt.hashSync(data.password, 10)
  });

  return (
    <Create {...props} transform={transform}>
      <SimpleForm toolbar={<UserCreateToolbar />}>
        <TextInput source="username" label="Username" />
        <TextInput source="lastname" label="Nom" />
        <TextInput source="firstname" label="Prénom" />
        <TextInput source="email" label="E-mail" />
        <TextInput source="company" label="Société" />
        <TextInput source="password" label="Mot de passe" type="password" />
      </SimpleForm>
    </Create>
  );
};

export default UserCreate;
