import * as React from "react";
import { List, Datagrid, TextField, FunctionField, ShowButton, CreateButton, ExportButton, TopToolbar  } from 'react-admin';
import ImportButton from './ImportButton'; 
import ListActions from './ListActions'; 
import PostPagination from './PostPagination';


const CustomerList = (props) => (
    <List  pagination={<PostPagination />} {...props} actions={<ListActions hasCreate={true} />}>
        <Datagrid>
            <TextField source="name" label="Nom" sortable />
            <TextField source="company" label="Nom de la société" />
            <FunctionField 
                label="Date d'ajout"
                render={record => new Date(record.createdAt).toLocaleDateString('fr-FR')} 
            />
            <TextField source="customerStatus" label="Statut" />
            <ShowButton label="Voir" />
        </Datagrid>
    </List>
);

export default CustomerList;
