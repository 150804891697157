import * as React from "react";
import { Show, SimpleShowLayout, TextField, EmailField } from 'react-admin';

const CustomerShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="name" label="Nom" />
                <TextField source="customerFunction" label="Fonction" />
                <TextField source="company" label="Nom de la société" />
                <TextField source="address" label="Adresse" />
                <TextField source="zipCode" label="Code postal" />
                <TextField source="city" label="Ville" />
                <TextField source="country" label="Pays" />
                <TextField source="phone" label="Téléphone" />
                <TextField source="email" label="E-mail" />
                <TextField source="customerStatus" label="Statut" />
                <TextField source="entity" label="Entité" />
                <TextField source="carrierAccountCode" label="Code de compte du transporteur" />
                <TextField source="comment" label="Commentaire" />
            </SimpleShowLayout>
        </Show>
    );
};

export default CustomerShow;
