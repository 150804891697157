import React from 'react';
import { Button } from '@mui/material';
import { useNotify, useRefresh, useDataProvider } from 'react-admin';
import Papa from 'papaparse';

const ImportButton = () => {
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const handleImport = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            Papa.parse(file, {
                header: true,
                complete: async (results) => {
                    try {
                        for (let row of results.data) {
                            await dataProvider.create('customers', { data: row });
                        }
                        notify('Importé avec succès');
                        refresh();
                    } catch (err) {
                        notify('Erreur lors de l\'importation : ' + err.message, 'warning');
                    }
                },
            });
        }
    };

    return (
        <Button variant="contained" component="label">
            Importer un fichier client
            <input type="file" hidden accept=".csv" onChange={handleImport} />
        </Button>
    );
};

export default ImportButton;
