import * as React from "react";
import { List, Datagrid, TextField, FunctionField, ShowButton, Filter, TextInput } from 'react-admin';
import ImportButtonProduct from "./ImportButtonProduct";

const ProductFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Rechercher" source="reference" alwaysOn />
    </Filter>
);

const ProductList = (props) => (
    console.log('PROPS',props),
    // <List {...props} filters={<ProductFilter />} actions={<ImportButtonProduct />}>
     <List {...props}  actions={<ImportButtonProduct />}>
        <Datagrid>
            <TextField source="reference" label="Reference" sortable />
            {/* <TextField source="factory" label="Usine" /> */}
            <FunctionField 
                label="Date d'ajout"
                render={record => new Date(record.addedAt).toLocaleDateString('fr-FR')} 
            />
            <ShowButton label="Voir" />
        </Datagrid>
    </List>
);

export default ProductList;