import React from 'react';
import { Admin, Resource, ListGuesser, Layout } from 'react-admin';
import { HydraAdmin, hydraDataProvider as baseDataProvider, fetchHydra as baseFetchHydra, openApiDataProvider, ResourceGuesser,AdminGuesser  } from '@api-platform/admin';
import { parseHydraDocumentation } from '@api-platform/api-doc-parser';
import authProvider from './authProvider';
import { fetchUtils } from 'ra-core';
import UserList from './UserList';
import UserShow from './UserShow';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit'; 
import CustomerList from './CustomerList';
import CustomerShow from './CustomerShow';
import PurchaseOrderList from './PurchaseOrderList';
import PurchaseOrderShow from './PurchaseOrderShow';
import PurchaseOrderEdit from './PurchaseOrderEdit';
import CustomerEdit from './CustomerEdit'; 
import CustomerCreate from './CustomerCreate';
import ProductList from './ProductList';
import ProductShow from './ProductShow';
import OrderProductShow from './OrderProductShow';
import polyglotI18nProvider from "ra-i18n-polyglot"; 
import engMessages from "ra-language-english"; 
import jsonServerProvider from 'ra-data-json-server';
import simpleRestProvider from 'ra-data-simple-rest';
import { stringify } from 'query-string';
import CustomLoginPage from './CustomLoginPage';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';



// const entrypoint = 'http://ecopelapi.local/api';
const entrypoint = 'https://api-ecopel.app.strategia.io/api';
//  const entrypoint = 'https://admin.ecopel.strategia-test.com/api';

const fetchHydra = (url, options = {}) => {
    console.log('fetchHydra url:', url);
    console.log('fetchHydra options:', options);
    return baseFetchHydra(url, {
        ...options,
        headers: new Headers({
            ...options.headers,
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }),
    });
};

const apiDocumentationParser = entrypoint => {
    console.log('apiDocumentationParser entrypoint:', entrypoint);
    return parseHydraDocumentation(entrypoint, { headers: new Headers({'Authorization': `Bearer ${localStorage.getItem('token')}`})})
    .then(
        ({ api }) => {
            console.log('apiDocumentationParser api:', api);
            return { api };
        },
        (result) => {
            console.log('apiDocumentationParser error result:', result);
            switch (result.status) {
                case 401:
                    return Promise.reject(new Error('API Platform admin configuration is not reachable. Please check your API Platform admin server running.'));
                default:
                    return Promise.reject(result);
            }
        },
    );
};

//const dataProvider = jsonServerProvider(entrypoint);
 const dataProvider = (apiUrl) => {
    const httpClient = (url, options = null) => {
        if(options) {
            options.headers = new Headers({ Accept: 'application/json' })
        }else {
            options = {headers: new Headers({ Accept: 'application/json' })}
        }
        const token = localStorage.getItem('token').replace('"','').replace('"','');
        if (token) {
            options.headers.set('Authorization', `Bearer ${token}`);
        }

        return fetchUtils.fetchJson(url, options);
    };

    return {
        getList: (resource, params) => {
            console.log('getList params:', params);  
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            
            const query = {
                ...fetchUtils.flattenObject(params.filter),
                page: page,
                itemsPerPage: perPage,
            };
            const newOrder = `&order[${field}]=${order}`;
            console.log('QUERY:', stringify(query));  
            const url = `${apiUrl}/${resource}?${stringify(query)}${newOrder}`;
    
            return httpClient(url).then(({ headers, json }) => {
                console.log('Response headers:', headers);
                console.log('Response json:', json);
                if (!headers.has('x-total-count')) {
                    throw new Error(
                        'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                    );
                }
                return {
                    data: json,
                    total: parseInt(
                        headers.get('x-total-count').split('/').pop(),
                        10
                    ),
                };
            });
        },
    
        getOne: (resource, params) =>
            httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
                data: json,
            })),
    
        getMany: (resource, params) => {
            const query = {
                id: params.ids,
            };
            const url = `${apiUrl}/${resource}?${stringify(query)}`;
            // return httpClient(url).then(({ json }) => ({ data: json }));
            return httpClient(url).then(({ json }) => {
                console.log('return', json)
                return    ({ data: json })
            });
        },

    
        getManyReference: (resource, params) => {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                ...fetchUtils.flattenObject(params.filter),
                [params.target]: params.id,
                _sort: field,
                _order: order,
                _start: (page - 1) * perPage,
                _end: page * perPage,
            };
            // const url = `${stringify(query)}`;
            const url = `${apiUrl}/${resource}?${stringify(query)}`;
            console.log('OKOK', url)
            return httpClient(url).then(({ headers, json }) => {
                console.log('return2', json)
                if (!headers.has('x-total-count')) {
                    throw new Error(
                        'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                    );
                }
                return {
                    data: json,
                    total: parseInt(
                        headers.get('x-total-count').split('/').pop(),
                        10
                    ),
                };
            });
        },
    
        update: (resource, params) => {
            console.log('Resource:', resource); // Should log 'purchase_orders'
            console.log('Params:', params); // Should log { id: yourRecordId, data: { status: 'newStatus' } }
            return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json }));
        },
    
        // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        updateMany: (resource, params) =>
            Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    })
                )
            ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
    
        create: (resource, params) =>
            httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({
                data: { ...params.data, id: json.id },
            })),
    
        delete: (resource, params) =>
            httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'DELETE',
            }).then(({ json }) => ({ data: json })),
    
        // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        deleteMany: (resource, params) =>
            Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'DELETE',
                    })
                )
            ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
    };
};

const i18nProvider = polyglotI18nProvider((locale) => {
    return engMessages;
}, "en", {
    allowMissing: true,
    onMissingKey: (key, _, __) => {
        return key;
    },
});



const App = () => {
    return (
        <Admin  loginPage={CustomLoginPage} authProvider={authProvider} dataProvider={dataProvider(entrypoint)} i18nProvider={i18nProvider}>
            <Resource name="users" list={UserList} show={UserShow} create={UserCreate} edit={UserEdit} options={{ label: 'Utilisateurs' }} />
            <Resource name="customers" list={CustomerList} show={CustomerShow} edit={CustomerEdit} create={CustomerCreate} options={{ label: 'Clients' }}  />
            <Resource name="products" list={ProductList} show={ProductShow} options={{ label: 'Produits' }} />
            <Resource name="purchase_orders" list={PurchaseOrderList} show={PurchaseOrderShow} edit={PurchaseOrderEdit} options={{ label: 'Commandes' }} />
        </Admin>
    );
};

export default App;