import { Edit, SimpleForm, TextInput, SelectInput, DateInput } from 'react-admin';

const PurchaseOrderEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <SelectInput source="status" label="Statut" choices={[
                { id: 'A traiter', name: 'A traiter' },
                { id: 'Traitée', name: 'Traitée' },
            ]} />
            {/* <DateInput source="createdAt" /> */}
        </SimpleForm>
    </Edit>
);

export default PurchaseOrderEdit;
