import * as React from "react";
import { List, Datagrid, TextField, EmailField, FunctionField, ShowButton, CreateButton, ExportButton, TopToolbar } from 'react-admin';
import PostPagination from './PostPagination';

const PostListActions = () => (
    <TopToolbar>
        {/* <PostFilter context="button" /> */}
        <CreateButton label="Ajouter un utilisateur" />
        <ExportButton label="Exporter la liste des utilisateurs" />
    </TopToolbar>
);

const UserList = (props) => (
    <List {...props} actions={<PostListActions />} pagination={<PostPagination />} >
        <Datagrid>
            <TextField source="lastname" label="Nom" sortable />
            <TextField source="firstname" label="Prénom" sortable />
            <FunctionField 
                label="Date d'ajout"
                render={record => new Date(record.createdAt).toLocaleDateString('fr-FR')} 
            />
            <EmailField source="email" label="E-mail" />
            <TextField source="company" label="Société" sortable />
            <ShowButton label="Voir" />
        </Datagrid>
    </List>
);

export default UserList;
