import * as React from "react";
import { Show, SimpleShowLayout, TextField, EmailField, EditButton, TopToolbar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const PostListActions = () => (
    <TopToolbar>
        <EditButton label="Modifier" /> 
    </TopToolbar>
);



const UserShow = (props) => {
    return (
        <Show {...props} actions={<PostListActions />}>
            <SimpleShowLayout>
                <TextField source="company" label="Société" />
                <TextField source="lastname" label="Nom" />
                <TextField source="firstname" label="Prénom" />
                <EmailField source="email" label="E-mail" />
            </SimpleShowLayout>
        </Show>
    );
};

export default UserShow;
