import * as React from "react";
import { List, Datagrid, TextField, FunctionField, TopToolbar, ExportButton, EditButton, ReferenceField, ShowButton, ChipField, ArrayField } from 'react-admin';
import ImportButton from './ImportButton'; 
import ListActions from './ListActions'; 
import StatusEditButton from './StatusEditButton';

const PostListActions = () => (
    <TopToolbar>
        <ExportButton label="Exporter la liste des commandes" />
    </TopToolbar>
);

const OrderList = (props) => (
    <List {...props} actions={<PostListActions />}>
        <Datagrid>
                <TextField source="id" label="Id" />
                <FunctionField 
                    label="Utilisateur"
                    render={record => `${record.user.firstname} ${record.user.lastname}`}
                    sortBy="user.firstname"
                    sortable
                />
                <FunctionField 
                    label="Société"
                    render={record => `${record.user.company}`}
                    sortBy="user.company" 
                    sortable
                />
                <FunctionField 
                    label="Client"
                    render={record => `${record.customer.name}`}
                    sortBy="customer.name" 
                    sortable
                />
                <FunctionField 
                    label="Ville de livraison"
                    render={record => `${record.customer.city}`}
                    sortBy="customer.city" 
                    sortable
                />
                <FunctionField 
                    label="Date d'ajout"
                    render={record => new Date(record.createdAt).toLocaleDateString('fr-FR')} 
                    sortBy="createdAt" 
                    sortable
                />
                <ChipField source="status" label="statut" sortable  />
                <ShowButton label="Voir" />
        </Datagrid>
    </List>
);

export default OrderList;
