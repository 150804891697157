import React from 'react';
import { Button } from '@mui/material';
import { useNotify, useRefresh, useDataProvider } from 'react-admin';
import Papa from 'papaparse';

const ImportButtonProduct = () => {
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const handleImport = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            Papa.parse(file, {
                header: true,
                delimiter: ',',
                quoteChar: '"', // Spécifiez le caractère de citation comme "
                dynamicTyping: true, // Permet de détecter automatiquement les types de données
                complete: async (results) => {
                    try {
                        for (let row of results.data) {
                            if (row.referenceType) {
                                row.referenceType = row.referenceType.toString(); // Convertir en chaîne de caractères
                            }
                            if (row.reference) {
                                row.reference = row.reference.toString(); // Convertir en chaîne de caractères
                            }
                            if (row.length) {
                                row.length = row.length.toString(); // Convertir en chaîne de caractères
                            }
                            if (row.width) {
                                row.width = row.width.toString(); // Convertir en chaîne de caractères
                            }
                            if (row.grammage) {
                                row.grammage = row.grammage.toString(); // Convertir en chaîne de caractères
                            }
                            if (row.garmentPrice) {
                                row.garmentPrice = row.garmentPrice.toString(); // Convertir en chaîne de caractères
                            }

                            if (row.composition) {
                                row.composition = row.composition.toString(); // Convertir en chaîne de caractères
                            }


                            row.priceInDollars300to499M = (row.priceInDollars300to499M === null || row.priceInDollars300to499M === undefined) 
                            ? "" 
                            : row.priceInDollars300to499M.toString(); // Convertir en chaîne de caractères

                            row.priceInDollars500to999M = (row.priceInDollars500to999M === null || row.priceInDollars500to999M === undefined) 
                            ? "" 
                            : row.priceInDollars500to999M.toString(); // Convertir en chaîne de caractères

                            row.priceInDollars1000M = (row.priceInDollars1000M === null || row.priceInDollars1000M === undefined) 
                            ? "" 
                            : row.priceInDollars1000M.toString(); // Convertir en chaîne de caractères

                            row.priceInDollars1000M = (row.priceInDollars1000M === null || row.priceInDollars1000M === undefined) 
                            ? "" 
                            : row.priceInDollars1000M.toString(); // Convertir en chaîne de caractères

                            row.color = (row.color === null || row.color === undefined) 
                            ? "" 
                            : row.color.toString(); // Convertir en chaîne de caractères

                            // if (row.priceInDollars500to999M) {
                            //     row.priceInDollars500to999M = row.priceInDollars500to999M.toString(); // Convertir en chaîne de caractères
                            // }

                            // if (row.priceInDollars1000M) {
                            //     row.priceInDollars1000M = row.priceInDollars1000M.toString(); // Convertir en chaîne de caractères
                            // }
 


                            
    
                            await dataProvider.create('products', { data: row });
                        }
                        notify('Importé avec succès');
                        refresh();
                    } catch (err) {
                        notify('Erreur lors de l\'importation : ' + err.message, 'warning');
                    }
                },
            });
            
        }
    };

    return (
        <Button variant="contained" component="label">
            Importer un fichier produit
            <input type="file" hidden accept=".csv" onChange={handleImport} />
        </Button>
    );
};

export default ImportButtonProduct;
