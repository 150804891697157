import * as React from "react";
import { Show, SimpleShowLayout, TextField, Link, Button, FunctionField,ReferenceArrayField,ArrayField,ReferenceManyField, ReferenceOneField, SimpleList, NumberField, ReferenceField, Datagrid } from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';

const PurchaseOrderShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="status" label="Statut" />
                <FunctionField 
                    label="Utilisateur"
                    render={record => `${record.user.firstname} ${record.user.lastname}`}
                />
                <FunctionField 
                    label="Client"
                    render={record => `${record.customer.name}`}
                />
                <FunctionField 
                    label="Adresse"
                    render={record => `${record.customer.address}`}
                />
                <FunctionField 
                    label="Code postal"
                    render={record => `${record.customer.zipCode}`}
                />
                <FunctionField 
                    label="Ville"
                    render={record => `${record.customer.city}`}
                />
                <FunctionField 
                    label="Pays"
                    render={record => `${record.customer.country}`}
                />
                <ArrayField source="products" label="Produits">
                    <Datagrid bulkActionButtons={false}>
                        <FunctionField
                            label="Référence"
                            render={record => <RouterLink to={`/products/${record.id}/show`}>{record.reference}</RouterLink>}
                        />
                        <TextField source="composition" />
                    </Datagrid>
                </ArrayField>
                <TextField source="comment" label="Commentaire" />
                <Link to="/purchase_orders">
                    <Button color="primary">
                        Retour à la liste des commandes
                    </Button>
                </Link>
            </SimpleShowLayout>
        </Show>
    );
};

export default PurchaseOrderShow;




